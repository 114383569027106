<template>
  <b-card id="pickup-order-detail">
    <b-row class="mb-2 px-1">
      <b-button
        variant="primary"
        class="rounded-lg my-auto"
        size="sm"
        @click="goBack"
      >
        <b-icon-chevron-left />
      </b-button>
      <span class="font-bold text-[22px] ml-2 my-auto">
        Detail Orderan Pickup
      </span>
    </b-row>
    <b-alert
      v-if="$route.params.order_data_id === undefined"
      variant="primary"
      class="p-1"
      show
      dismissible
    >
      *Ajukan pickup terlebih dahulu untuk mendapatkan label dan nomor resi
    </b-alert>
    <b-row
      v-else
      class="p-0 mb-1"
    >
      <b-col
        cols="12"
        class="text-right"
      >
        <v-select
          v-model="isPrint"
          :options="listPrint"
          placeholder="Semua"
          class="d-inline-block mb-1 mr-1"
          style="width:205px"
          @input="getOrderDataByPrint"
        />
        <v-select
          v-model="shipment"
          :options="listShipment"
          placeholder="Pilih Ekspedisi"
          class="d-inline-block mb-1"
          style="width:205px"
          @input="getOrderDataByExpedition"
        />
        <b-button
          :variant="listOrderPrint.length < 1 ? 'secondary' : 'primary'"
          class="d-inline-block ml-1"
          :disabled="listOrderPrint.length < 1"
          @click="popupPrintLabel"
        >
          <span class="text-[14px] font-semibold">Print Label
            <span v-if="listOrderPrint.length > 0">({{ listOrderPrint.length }})</span>
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-overlay
      variant="light"
      :show="loading"
      spinner-variant="primary"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <b-table
        hover
        striped
        show-empty
        responsive
        empty-text="Tidak ada data untuk ditampilkan."
        :busy="loading"
        :fields="fieldOrder"
        :items="order"
      >
        <template #head(no)>
          <div v-if="$route.params.order_data_id === undefined">
            No
          </div>
          <div v-else>
            <b-form-checkbox
              v-model="checklistAllOrder"
              @input="selectAllOrder"
            />
          </div>
        </template>
        <template #cell(no)="data">
          <div v-if="$route.params.order_data_id === undefined">
            {{ data.index + 1 }}
          </div>
          <div v-else>
            <b-form-checkbox
              v-model="listOrderPrint"
              :value="data.item"
            />
          </div>
        </template>
        <template #cell(order_date)="data">
          <span class="font-bold">{{ data.item.order_date.slice(0, 10) }}</span><br>
          <span>{{ data.item.order_date.slice(11, -3) }}</span>
        </template>
        <template #cell(customer_name)="data">
          <span
            class="font-bold"
          >{{ data.item.customer_name }}</span><br>
          <div
            class="d-flex items-center gap-2 mt-[4px]"
          >
            <img
              :src="data.item.shipping_image"
              class="w-[25%] h-[25%]"
            ><span>{{ data.item.shipment_alias }}</span>
          </div>
        </template>
        <template #cell(product)="data">
          <div style="min-width: 700px;">
            <div v-if="data.item.product[0]">
              <div
                class="d-flex"
                style="min-width:160px!important"
              >
                <div v-if="data.item.product[0].product_image === null">
                  <img
                    class="image-product"
                    :src="imageNull"
                  >
                </div>
                <div v-else>
                  <img
                    class="image-product"
                    :src="data.item.product[0].product_image"
                    @error="setImageDefault"
                  >
                </div>
                <div
                  class="ml-1 w-[70%]"
                >
                  <span class="font-bold">{{ data.item.product[0].product_name }}</span><br>
                  <span
                    v-if="data.item.product[0].variant_name !== '0' && data.item.product[0].is_bundling === 0"
                    class="text-primary"
                  >{{ data.item.product[0].variant_name }}</span>
                  <div
                    v-if="data.item.product[0].is_bundling === 1"
                    class="flex mt-[4px]"
                  >
                    <div
                      v-b-toggle="`bundling-${String(data.item.product[0].detail_id)}`"
                      class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                    >
                      <img
                        src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                        alt="bundling"
                        width="16"
                      >
                      <div>Bundling</div>
                    </div>
                  </div>
                  <b-collapse
                    :id="`bundling-${String(data.item.product[0].detail_id)}`"
                    class="min-w-max"
                  >
                    <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                      <div class="border rounded-t">
                        <div class="bg-[#FFECE9] py-[8px] px-[24px] rounded-t text-black font-[500]">
                          Produk
                        </div>
                        <b-table
                          :items="data.item.product[0].bundle_component"
                          :fields="fieldsBundling"
                        >
                          <template #cell(no)="bundle">
                            {{ bundle.index + 1 }}
                          </template>
                          <template #cell(product_name)="bundle">
                            <div class="flex gap-[16px] items-start">
                              <img
                                :src="bundle.item.product_images[0] ? bundle.item.product_images[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                alt="product"
                                width="52"
                                height="52"
                              >
                              <div class="space-y-[4px]">
                                <div class="text-[14px]">
                                  {{ bundle.item.product_name }}
                                </div>
                                <div class="text-[12px] text-primary">
                                  SKU: {{ bundle.item.product_sku }}
                                </div>
                              </div>
                            </div>
                          </template>
                          <template #cell(variant)="bundle">
                            {{ bundle.item.product_variant_name }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="flex ml-[0.8rem]">
                      <div
                        v-b-toggle="`bundling-${String(data.item.product[0].detail_id)}`"
                        class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                      >
                        <feather-icon
                          icon="ChevronUpIcon"
                        />
                        <div>Tutup</div>
                      </div>
                    </div>
                  </b-collapse>
                </div>
                <div
                  class="ml-1 font-bold w-[10%]"
                >
                  x{{ data.item.product[0].qty }}
                </div>
              </div>
              <div
                v-if="data.item.product.length > 1"
                style="min-width:160px!important"
              >
                <b-collapse :id="'collapse-'+data.item.order_id">
                  <div
                    v-for="item in data.item.product.slice(1)"
                    :key="item.order_id"
                    class="d-flex mt-1"
                    style="min-width:160px!important"
                  >
                    <div v-if="item.product_image === null">
                      <img
                        class="image-product"
                        :src="imageNull"
                      >
                    </div>
                    <div v-else>
                      <img
                        class="image-product"
                        :src="item.product_image"
                        @error="setImageDefault"
                      >
                    </div>
                    <div class="ml-1 w-[70%]">
                      <span class="font-bold">{{ item.product_name }}</span><br>
                      <span
                        v-if="item.is_bundling === 0"
                        class="text-primary"
                      >{{ item.variant_name }}</span>
                      <div
                        v-if="item.is_bundling === 1"
                        class="flex mt-[4px]"
                      >
                        <div
                          v-b-toggle="`bundling-${String(item.detail_id)}`"
                          class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#08A0F7] text-white font-[500] cursor-pointer"
                        >
                          <img
                            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/gift.svg"
                            alt="bundling"
                            width="16"
                          >
                          <div>Bundling</div>
                        </div>
                      </div>
                      <b-collapse
                        :id="`bundling-${String(item.detail_id)}`"
                        class="min-w-max"
                      >
                        <div class="border-l-[2px] h-min p-[16px] ml-[3.5rem]">
                          <div class="border rounded-t">
                            <div class="bg-[#FFECE9] py-[8px] px-[24px] rounded-t text-black font-[500]">
                              Produk
                            </div>
                            <b-table
                              :items="item.bundle_component"
                              :fields="fieldsBundling"
                            >
                              <template #cell(no)="bundle">
                                {{ bundle.index + 1 }}
                              </template>
                              <template #cell(product_name)="bundle">
                                <div class="flex gap-[16px] items-start">
                                  <img
                                    :src="bundle.item.product_images[0] ? bundle.item.product_images[0].images_path : 'https://storage.googleapis.com/komerce/assets/icons/product-placehold.svg'"
                                    alt="product"
                                    width="52"
                                    height="52"
                                  >
                                  <div class="space-y-[4px]">
                                    <div class="text-[14px]">
                                      {{ bundle.item.product_name }}
                                    </div>
                                    <div class="text-[12px] text-primary">
                                      SKU: {{ bundle.item.product_sku }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <template #cell(variant)="bundle">
                                {{ bundle.item.product_variant_name }}
                              </template>
                            </b-table>
                          </div>
                        </div>
                        <div class="flex ml-[0.8rem]">
                          <div
                            v-b-toggle="`bundling-${String(item.detail_id)}`"
                            class="flex items-center gap-[4px] py-[4px] px-[8px] rounded-[12px] !bg-[#F95031] text-white font-[500] cursor-pointer"
                          >
                            <feather-icon
                              icon="ChevronUpIcon"
                            />
                            <div>Tutup</div>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                    <div class="ml-1 font-bold w-[10%]">
                      x{{ item.qty }}
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
            <div
              v-if="data.item.product.length > 1"
              class="d-flex justify-content-end mt-1 mb-1"
            >
              <b-button
                v-b-toggle="'collapse-'+data.item.order_id"
                class="buttonCollapse relative p-0"
                variant="none"
                size="sm"
              >
                <span class="when-open">Tutup <b-icon-chevron-up /></span>
                <span class="when-closed">{{ data.item.product.length - 1 }} Produk lainnya <b-icon-chevron-down /></span>
              </b-button>
            </div>
          </div>
        </template>
        <template #cell(airway_bill)="data">
          <div>{{ data.item.airway_bill }}</div>
          <div
            v-if="data.item.is_print === 1"
          >
            <div
              :id="`${String(data.item.airway_bill)}`"
              class="label-after-print"
            >
              Tercetak
            </div>
            <b-popover
              :target="`${String(data.item.airway_bill)}`"
              triggers="hover focus"
              placement="left"
              custom-class="custom-popover"
            >
              <span class="text-white">Label PDF di orderan resi ini pernah didownload untuk dicetak</span>
            </b-popover>
          </div>
        </template>
        <template #cell(warehouse_type)="data">
          <div>{{ data.item.fulfillment_fee }}</div>
        </template>
        <template #head(warehouse_type)>
          <div>Biaya Fulfillment</div>
          <div
            class="d-flex mt-1 text-[12px]"
            style="place-content: center"
          >
            <span class="text-capitalize pr-[5px]">Layanan</span><span class="text-lowercase"> dari</span>
            <img
              class="px-[5px]"
              src="https://storage.googleapis.com/komerce/assets/svg/logo_kompack.svg"
            >
            <span>Kompack</span>
          </div>
        </template>
      </b-table>
    </b-overlay>
    <b-modal
      id="printLabel"
      size="xl"
      hide-header
      scrollable
    >
      <b-img
        src="@/assets/images/icons/close-circle.svg"
        class="absolute right-[1rem] top-[1rem]"
        role="button"
        @click="$bvModal.hide('printLabel')"
      />
      <p class="text-center font-bold text-[24px] my-3">
        Pilih Bentuk Print Label
      </p>
      <b-row class="justify-content-center">
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_1' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_1'"
          >
            <div class="label-preview">
              <div class="label-preview-top" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              1 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_2' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_2'"
          >
            <div class="label-preview">
              <div class="label-preview-top" />
              <div class="label-preview-bottom" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              2 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_4' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_4'"
          >
            <div class="label-preview">
              <div class="label-preview-topleft" />
              <div class="label-preview-topright" />
              <div class="label-preview-bottomleft" />
              <div class="label-preview-bottomright" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              4 Label / Halaman
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Direkomandasikan kertas A4
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_5' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_5'"
          >
            <div class="label-preview">
              <div class="label-preview-full" />
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              10 cm x 10cm
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Printer Thermal
            </p>
          </b-card>
        </b-col>
        <b-col cols="auto">
          <b-card
            class="card-print-label"
            :class="formatPrint === 'page_6' ? 'active' : ''"
            role="button"
            @click="formatPrint = 'page_6'"
          >
            <div class="px-2">
              <div class="label-preview">
                <div class="label-preview-full" />
              </div>
            </div>
            <p class="text-[#222222] text-[14px] font-semibold my-1">
              10 cm x 15cm
            </p>
            <p class="text-[#828282] text-[14px] font-medium mb-0">
              Printer Thermal
            </p>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-row>
          <b-col cols="12">
            <b-row class="justify-content-end align-items-center pb-2 wrapper__handle__print__label">
              <b-form-checkbox
                v-model="printDateItem"
                class="custom-control-primary mr-2"
              >
                Tambahkan tanggal cetak di label
              </b-form-checkbox>
              <b-button
                variant="primary"
                :class="isDownloadActive ? 'mr-3 py-1 px-3 cursor-not-allowed' : 'mr-3 py-1 px-3'"
                :disabled="isDownloadActive || formatPrint === ''"
                @click="getPrintLabelBase64"
              >
                Download Label
              </b-button>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div
              v-if="percentageDownload !== 0"
              class="d-flex justify-content-end mr-2"
            >
              <div>
                <b-row class="text-center justify-content-center">
                  <span>sedang menyiapkan file</span>
                </b-row>
                <div class="mr-50">
                  <b-progress
                    :value="percentageDownload"
                    max="100"
                    variant="primary"
                    style="min-width: 198px; height: 3px;"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-modal>

    <b-modal
      ref="popup-warning-downloading"
      hide-footer
      hide-header
      static
      centered
    >
      <b-row class="justify-content-center mb-1 pt-1">
        <img :src="warningIcon">
      </b-row>
      <b-row class="justify-content-center text-center mb-1">
        <h4 class="text-black px-4">
          <strong>
            Batalin Download Label?
          </strong>
        </h4>
      </b-row>
      <b-row class="justify-content-center text-center mb-1">
        <span class="text-black">
          Proses penyiapan file label pengiriman belum selesai.
        </span>
        <br>
        <span class="text-black">
          Yakin mau Batalin?
        </span>
      </b-row>
      <b-row class="justify-content-center pb-1">
        <b-col cols="5">
          <b-button
            class="btn-icon"
            variant="primary"
            block
          >
            Lanjutin
          </b-button>
        </b-col>
        <b-col cols="5">
          <b-button
            class="btn-icon"
            variant="outline-primary"
            block
          >
            Batalin Download
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>
<script>
import vSelect from 'vue-select'
import imageNull from '@/assets/images/avatars/image-null.png'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import useJwt from '@core/auth/jwt/useJwt'
import '@core/scss/vue/libs/vue-select.scss'
import { mapState } from 'vuex'
import warningIcon from '@/assets/images/icons/warning.svg'

const { jwt } = useJwt(axios, {})
const token = jwt.getToken()
const httpKomship = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_BASE_URL_KOMSHIP,
})
export default {
  components: { vSelect },
  data() {
    return {
      profile: JSON.parse(localStorage.userData),
      fieldOrder: [
        {
          key: 'no', label: '', thClass: 'text-center', tdClass: 'text-center align-top',
        },
        { key: 'order_date', label: 'Tanggal Order', tdClass: 'align-top pr-0' },
        { key: 'customer_name', label: 'Pelanggan', tdClass: 'align-top' },
        { key: 'product', label: 'Produk', tdClass: 'align-top' },
        { key: 'detail_address', label: 'Alamat', tdClass: 'align-top' },
        {
          key: 'airway_bill', label: 'Resi', thClass: 'text-center', tdClass: 'align-top text-center',
        },
      ],
      orderID: '',
      order: [],
      orderDB: [],
      loading: false,
      shipment: 'Semua Ekspedisi',
      listShipment: [],
      listOrderPrint: [],
      orderIdPrint: '',
      checklistAllOrder: false,
      page: 1,
      limit: 50,
      offset: 0,
      lastOrderData: false,
      formatPrint: '',
      printDate: false,
      paramsBase64: '',
      printDateItem: false,
      percentageDownload: 0,
      isDownloadActive: false,
      warningIcon,
      listPrint: [
        { value: 2, label: 'Semua' },
        { value: 1, label: 'Tercetak' },
        { value: 0, label: 'Belum Tercetak' },
      ],
      isPrint: null,

      fieldsBundling: [
        {
          key: 'no',
          label: 'No',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
        {
          key: 'product_name',
          label: 'Nama Produk',
          thClass: 'font-[500] text-[#222] !text-[14px] !capitalize bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
        {
          key: 'variant',
          label: 'Variasi',
          thClass:
      'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282] bg-white max-w-[100px]',
        },
        {
          key: 'qty',
          label: 'Jumlah',
          thClass:
      'font-[500] text-[#222] !text-[14px] !capitalize text-center bg-white',
          tdClass: 'text-[#828282] bg-white',
        },
      ],
    }
  },
  computed: {
    ...mapState('saldo', ['dateToPrint']),
    ...mapState('filter', ['expedition']),
  },
  async created() {
    this.getShipment()
    if (this.$route.params.order_data_id !== undefined) {
      await this.getOrderID()
    } else if (this.$route.params.order) {
      let orderID = ''
      const orderData = this.$route.params.order
      for (let index = 0; index < orderData.length; index += 1) {
        if (index === orderData.length - 1) {
          orderID += `${orderData[index].order_id}`
        } else {
          orderID += `${orderData[index].order_id},`
        }
      }
      this.orderID = orderID
    }
    this.getOrderData()
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('pickup-order-detail').offsetHeight && !this.loading) {
        this.getOrderData()
      }
    }
  },
  methods: {
    setImageDefault(e) {
      e.target.src = imageNull
    },
    async getShipment() {
      if (this.expedition.length === 0) {
        this.$store.dispatch('filter/getUpdateExpedition')
      }
      this.listShipment = this.expedition.map(item => item.shipping_name)
    },
    goBack() {
      if (this.$route.params.order_data_id === undefined) {
        this.$router.push({
          name: 'ajukan-pickup',
          params: {
            address: this.$route.params.address,
            order: this.$route.params.order,
            pickup_date: this.$route.params.pickup_date,
            pickup_time: this.$route.params.pickup_time,
            vehicle: this.$route.params.vehicle,
          },
        })
      } else {
        this.$router.go(-1)
      }
    },
    async getOrderID() {
      try {
        const listOrderId = await this.$http_komship.get(
          `/v1/pickup/history/detail/order/${this.$route.params.order_data_id}`,
        )
        const { data } = listOrderId.data
        let orderID = ''
        for (let index = 0; index < data.length; index += 1) {
          if (index === data.length - 1) {
            orderID += `${data[index].order_id}`
          } else {
            orderID += `${data[index].order_id},`
          }
        }
        this.orderID = orderID
      } catch (error) {
        console.error(error)
      }
    },
    async getOrderData() {
      if (this.$route.params.order_data_id !== undefined) {
        if (!this.lastOrderData) {
          this.loading = true
          if (this.isPrint === null) {
            this.isPrint = { value: 2, label: 'Semua' }
          }
          try {
            const order = await this.$http_komship.get(`/v3/pickup/detail/order/${this.$route.params.order_data_id}`, {
              params: {
                limit: this.limit,
                offset: this.offset,
                shipping_name: this.shipment === 'Semua Ekspedisi' ? '' : this.shipment,
                is_print: this.isPrint.value,
              },
            })
            const { data } = order.data
            this.offset += data.length
            this.orderDB = data
            this.order.push(...data)
            if (this.order[0].warehouse_type === 'Mitra Kompack') {
              this.fieldOrder.push(
                {
                  key: 'warehouse_type', label: 'Biaya Fulfillment', thClass: 'text-center', tdClass: 'align-top text-center', labelBottom: 'Layanan dari Kompack',
                },
              )
            }
            this.page += 1
            this.loading = false
            if (data.length < this.limit) {
              this.lastOrderData = true
            }
          } catch (error) {
            console.error(error)
            this.loading = false
          }
        }
      } else if (!this.lastOrderData) {
        this.loading = true
        try {
          const order = await this.$http_komship.get(`/v3/order/${this.profile.partner_detail.id}`, {
            params: {
              order_id: this.orderID,
              limit: this.limit,
              offset: this.offset,
              shipping_name: this.shipment === 'Semua Ekspedisi' ? '' : this.shipment,
            },
          })
          const { data } = order.data
          this.offset += data.length
          this.orderDB = data
          this.order.push(...data)
          if (this.$route.params.warehouse_type === 'Mitra Kompack') {
            this.fieldOrder.push(
              {
                key: 'warehouse_type', label: 'Biaya Fulfillment', thClass: 'text-center', tdClass: 'align-top text-center', labelBottom: 'Layanan dari Kompack',
              },
            )
          }
          this.page += 1
          this.loading = false
          if (data.length < this.limit) {
            this.lastOrderData = true
          }
        } catch (error) {
          console.error(error)
          this.loading = false
        }
      }
    },
    async getOrderDataByExpedition() {
      this.limit = 50
      this.offset = 0
      this.lastOrderData = false
      this.checklistAllOrder = false
      this.order = []
      this.listOrderPrint = []
      this.getOrderData()
    },
    async getOrderDataByPrint() {
      this.limit = 50
      this.offset = 0
      this.lastOrderData = false
      this.checklistAllOrder = false
      this.order = []
      this.listOrderPrint = []
      this.getOrderData()
    },
    selectAllOrder() {
      if (this.checklistAllOrder) {
        this.listOrderPrint = this.order
      } else {
        this.listOrderPrint = []
      }
    },
    async popupPrintLabel() {
      window.addEventListener('beforeunload', event => {
        if (this.isDownloadActive) {
          // eslint-disable-next-line no-param-reassign
          event.returnValue = ''
        }
      })
      this.$forceUpdate()
      this.$bvModal.show('printLabel')
      let orderIdPrint = ''
      for (let index = 0; index < this.listOrderPrint.length; index += 1) {
        if (index === this.listOrderPrint.length - 1) {
          orderIdPrint += `${this.listOrderPrint[index].order_id}`
        } else {
          orderIdPrint += `${this.listOrderPrint[index].order_id},`
        }
      }
      this.orderIdPrint = orderIdPrint
    },
    async downloadPrintLabel() {
      try {
        const print = await httpKomship.post('/v2/generate/print-label', null, {
          params: {
            order_id: this.orderIdPrint,
            page: this.formatPrint,
            print_date: this.printDate ? 1 : 0,
          },
        })
        const { data } = print.data
        const { path } = data
        const split = path.split('/')
        const fileName = split[split.length - 1]
        const url = `data:application/pdf;base64,${path}`
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        link.click()
        window.open(path, '_blank')
      } catch (error) {
        console.error(error)
      }
    },
    getPrintLabelBase64() {
      this.isDownloadActive = true
      const self = this
      this.loadingButtonPrintLabel = true
      let percent = null
      percent = setInterval(() => {
        if (self.percentageDownload < 100) self.percentageDownload += 1
        if (self.percentageDownload === 90) self.percentageDownload -= 1
      }, 500)
      const formData = new FormData()
      formData.append('order_id', this.orderIdPrint)
      formData.append('page', this.formatPrint)
      if (this.printDateItem) formData.append('print_date', 1)
      axios.post(`${process.env.VUE_APP_BASE_URL_NEW}/label/api/v2/generate/print-label`, formData).then(response => {
        try {
          let result = null
          const date = `${this.dateToPrint.pickupDate}T${this.dateToPrint.pickupTime}`
          result = `label-${this.$moment(date).format('YYYY-MM-DD-HH-mm-ss')}`
          this.percentageDownload = 100
          clearInterval(percent)
          const linkSource = `data:application/pdf;base64,${response.data.data.base_64}`
          const downloadLink = document.createElement('a')
          const fileName = `${result}.pdf`
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.isDownloadActive = false
          setTimeout(() => {
            this.percentageDownload = 0
            window.open(response.data.data.path, '_blank')
          }, 1000)
        } catch (e) {
          this.percentageDownload = 0
          this.isDownloadActive = false
          clearInterval(percent)
          // eslint-disable-next-line no-alert
          alert('Pop-up Blocker is enabled! Please add this site to your exception list.')
        }
        this.loadingButtonPrintLabel = false
        this.listOrderPrint = []
        this.checklistAllOrder = false
      }).catch(() => {
        this.loadingButtonPrintLabel = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failure',
            icon: 'AlertCircleIcon',
            text: 'Gagal print label, silahkan coba lagi!',
            variant: 'danger',
          },
        })
        clearInterval(percent)
        this.percentageDownload = 0
        this.isDownloadActive = false
      })
    },
  },
}
</script>
<style lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.custom-control-label::before{
  border: 1px solid #828282;
}
#printLabel.modal{
  width: 100%;
  max-width: 1200px;
  left: 50%;
  transform: translateX(-50%);
}
.card-print-label{
  border: 2px solid #E2E2E2;
  border-radius: 6px;
  width: 280px;
  margin: 7px;
  display: inline-block;
  text-align: left;
  padding: 7px;
}
.card-print-label:hover{
  border: 2px solid #F95031;
}
.card-print-label:hover .label-preview{
  border: 1px solid #F95031;
}
.card-print-label:hover .label-preview-full{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-top{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-topleft{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-topright{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottom{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottomleft{
  background-color: #FFECE9;
}
.card-print-label:hover .label-preview-bottomright{
  background-color: #FFECE9;
}
.card-print-label.active{
  border: 2px solid #F95031;
}
.card-print-label.active .label-preview{
  border: 1px solid #F95031;
}
.card-print-label.active .label-preview-full{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-top{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-topleft{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-topright{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottom{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottomleft{
  background-color: #FFECE9;
}
.card-print-label.active .label-preview-bottomright{
  background-color: #FFECE9;
}
.label-preview{
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  height: 240px;
  width: 100%;
  padding: 10px;
}
.label-preview-full{
  background-color: #E2E2E2;
  height: 100%;
  border-radius: 6px;
}
.label-preview-top{
  background-color: #E2E2E2;
  height: 48%;
  margin-bottom: 4%;
  border-radius: 6px;
}
.label-preview-topleft{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-bottom: 2%;
  margin-right: 2%;
  border-radius: 6px;
}
.label-preview-topright{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-bottom: 2%;
  margin-left: 2%;
  border-radius: 6px;
}
.label-preview-bottom{
  background-color: #E2E2E2;
  height: 48%;
  margin-bottom: 2%;
  border-radius: 6px;
}
.label-preview-bottomleft{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-top: 2%;
  margin-right: 2%;
  border-radius: 6px;
}
.label-preview-bottomright{
  display: inline-block;
  background-color: #E2E2E2;
  height: 48%;
  width: 48%;
  margin-top: 2%;
  margin-left: 2%;
  border-radius: 6px;
}
.image-product {
  object-fit: cover;
  object-position: center center;
  width: 50px!important;
  height: 50px!important;
}
.label-after-print {
  background-color: #BEFCDE;
  color: #34A770;
  border-radius: 12px;
  margin-top: 10px;
}
.custom-popover {
  background: #222222;
  color: #ffffff;
}
.custom-popover .arrow::after {
  border-left-color: #222222 !important;
}
@media (max-width: 576px) {
  .card-print-label {
    width: 200px!important;
  }
  .label-preview {
    height: 200px!important;
  }
}
</style>
